import Vue from 'vue';
import router from './router';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import './assets/scss/bootstrap-custom.scss';

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
