import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': process.env.VUE_APP_API_KEY
  }
});

// プランチェック
export async function checkPlan(params) {
  const config = {
    params: params
  };

  const res = await api.get('/member', config);
  return res;
}

// ユーザー仮登録
export async function signUpTmp(accessToken, body) {
  api.defaults.headers.common['X-LineToken'] = accessToken;

  const res = await api.post('/users/tmp', body);
  return res;
}

//ユーザー情報変更
export async function updateUser(accessToken, body) {
  api.defaults.headers.common['X-LineToken'] = accessToken;

  const res = await api.patch('/users', body);
  return res;
}

// ユーザー情報有効化
export async function activateUser(body) {
  const res = await api.post('/users/activate', body);
  return res;
}

//ユーザー情報取得
export async function getUser(accessToken, lineId) {
  api.defaults.headers.common['X-LineToken'] = accessToken;

  // 空のdataを送信しないとContent-Typeヘッダーが送信されない
  const res = await api.get('/user?line_id=' + lineId, { data: {} });
  return res;
}

//店舗情報取得
export async function getStore(accessToken, storeId) {
  api.defaults.headers.common['X-LineToken'] = accessToken;
  const res = await api.get(`/stores/${storeId}`);
  return res;
}

//利用可能時間取得
export async function getFacilityAvailableTime(accessToken, params) {
  api.defaults.headers.common['X-LineToken'] = accessToken;

  const config = {
    params: params
  };

  const res = await api.get('/stores/available-datetime', config);
  return res;
}

//仮予約登録
export async function reserveTmp(accessToken, body) {
  api.defaults.headers.common['X-LineToken'] = accessToken;

  const res = await api.post('/reservations/tmp', body);
  return res;
}

//予約本登録
export async function reserve(accessToken, body) {
  api.defaults.headers.common['X-LineToken'] = accessToken;

  const res = await api.post('/reservations', body);
  return res;
}

//予約確認
export async function confirmReservation(accessToken, params) {
  api.defaults.headers.common['X-LineToken'] = accessToken;

  const config = {
    params: params
  };

  const res = await api.get('/reservations/confirmation', config);
  return res;
}
