<template>
  <div class="register-result">
    <Spinner v-if="isBusy" />
    <div class="message">
      <div v-if="uuid !== undefined && uuid !== ''">
        <div v-if="registerType === 'signup' && statuscode === 204">
          <div class="success-msg">利用登録が完了しました。</div>
          <div>
            次は、<span class="text-primary"
              ><a :href="lineAccountURL">こちら</a></span
            >をタップしてメニューから「Reserve」を選んでください。
          </div>
        </div>
        <div
          v-if="
            (registerType === 'signup' || registerType === 'profile') &&
            statuscode === 400
          "
        >
          <div class="failure-msg">
            メールアドレスの確認ができませんでした。
          </div>
        </div>
        <div v-if="registerType === 'signup' && statuscode === 400">
          <div>確認メールの有効期限が切れている可能性がございます。</div>
          <div>
            お手数ですが、<span class="text-primary"
              ><a :href="lineAccountURL">こちら</a></span
            >をタップして、再度利用登録をお願いいたします。
          </div>
        </div>
        <div v-if="registerType === 'profile' && statuscode === 204">
          <div class="success-msg">プロフィールの登録が完了しました。</div>
          <div>
            変更内容は、<span class="text-primary"
              ><a :href="lineAccountURL">こちら</a></span
            >をタップしてメニューの「Profile」からご確認いただけます。
          </div>
        </div>
        <div v-if="registerType === 'signup' && statuscode === 500">
          <div class="failure-msg">
            システムエラーにより、利用登録に失敗しました。
          </div>
        </div>
        <div v-if="registerType === 'profile' && statuscode === 500">
          <div class="failure-msg">
            システムエラーによりプロフィール変更に失敗しました。
          </div>
        </div>
        <div
          v-if="
            (registerType === 'signup' || registerType === 'profile') &&
            statuscode === 500
          "
        >
          <div>
            お手数ですが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。
          </div>
        </div>
        <div v-if="registerType === 'profile' && statuscode === 400">
          <div>確認メールの有効期限が切れている可能性がございます。</div>
          <div>
            お手数ですが、<span class="text-primary"
              ><a :href="lineAccountURL">こちら</a></span
            >をタップして、再度プロフィール変更をお願いいたします。
          </div>
        </div>
      </div>
      <div v-else>
        <div class="failure-msg">
          システムエラーにより、利用登録に失敗しました。
        </div>
        <div>
          お手数ですが、<span class="text-primary"
            ><a :href="lineAccountURL">こちら</a></span
          >をタップして、再度利用登録をお願いいたします。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activateUser } from '../api';
import Spinner from '../components/Spinner.vue';

export default {
  components: {
    Spinner
  },
  data: () => {
    return {
      uuid: '',
      registerType: '',
      lineAccountURL: process.env.VUE_APP_LINE_ACCOUNT_URL,
      statuscode: null,
      isBusy: true
    };
  },
  async created() {
    this.isBusy = true;

    const query = this.$route.query;

    this.uuid = query['uuid'];
    this.registerType = query['register-type'];

    if (this.uuid == undefined) {
      this.isBusy = false;
      throw new Error('uuid undefined');
    }

    try {
      const body = {
        uuid: this.uuid
      };
      const res = await activateUser(body);
      this.statuscode = res.status;
    } catch (err) {
      this.statuscode = err.response.status;
    } finally {
      this.isBusy = false;
    }
  }
};
</script>

<style scoped>
.register-result {
  padding: 0 10px;
}
.success-msg {
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
}
.failure-msg {
  font-size: 20px;
  font-weight: bold;
  color: red;
  margin-bottom: 10px;
}
</style>
