<template>
  <b-container class="reserve-facility-form">
    <PlainTextFlashMessage
      :show-alert="showPlainText"
      :message-type="varient"
      :message="plainTextMessage"
      @hidePlainTextFlashMessage="makeShowPlainTextFlagFalse" 
    />
    <FlashMessageWithHyperlink
      :show-alert="showTextWithHyperLink"
      :message-type="varient"
      :first-half-message="firstHalfMessage"
      :hyperlink="hyperlink"
      :hyperlink-message="hyperlinkMessage"
      :later-half-message="laterHalfMessage"
      @hideFlashMessageWithHyperLink="makeShowTextWithHyperLinkFlagFalse" 
    />
    <ReservingForm
      reservation-type="facility"
      :access-token="accessToken"
      @needsFlashMessage="displayFlashMessage"
      @needsReservationModal="displayModal"
    />
    <ReservationModal
      reservation-type="facility"
      :access-token="accessToken"
      :reservation-info="reservationInfo"
      :needs-modal="needsModal"
      @needsFlashMessage="displayFlashMessage"
      @cancelReservation="makeNeedsModalFlagFalse"
    />
  </b-container>
</template>

<script>
import liff from '@line/liff';
import PlainTextFlashMessage from '../components/PlainTextFlashMessage.vue';
import FlashMessageWithHyperlink from '../components/FlashMessageWithHyperlink.vue';
import ReservingForm from '../components/ReservingForm.vue';
import ReservationModal from '../components/ReservationModal.vue';

export default {
  components: {
    PlainTextFlashMessage,
    FlashMessageWithHyperlink,
    ReservingForm,
    ReservationModal
  },
  props: {},
  data() {
    return {
      showPlainText: false,
      showTextWithHyperLink: false,
      accessToken: '',
      varient: 'success',
      plainTextMessage: '',
      firstHalfMessage: '',
      hyperlink: '',
      hyperlinkMessage: '',
      laterHalfMessage: '',
      reservationInfo: {
        storeName: '',
        facilityName: '',
        targetFacility: '',
        date: '',
        startTime: '',
        endTime: '',
        isoStartTime: '',
        isoEndTime: '',
        usageFee: 0
      },
      needsModal: false
    };
  },
  async created() {
    await liff.init({
      liffId: process.env.VUE_APP_LIFF_ID,
      withLoginOnExternalBrowser: false
    });
    this.accessToken = await this.getLineAccessToken();
  },
  methods: {
    getLineAccessToken() {
      const accessToken = liff.getAccessToken();
      return accessToken;
    },
    displayFlashMessage(...args) {
      const [flashType, varient, messageObject] = args;
      if (varient == 'success') {
        this.varient = 'success';
      } else if (varient == 'error') {
        this.varient = 'danger';
      }
      if (flashType == 'plain') {
        this.plainTextMessage = messageObject['message'];
        this.showPlainText = true;
      } else if (flashType == 'withHyperlink') {
        this.firstHalfMessage = messageObject['firstHalfMessage'];
        this.hyperlink = messageObject['hyperlink'];
        this.hyperlinkMessage = messageObject['hyperlinkMessage'];
        this.laterHalfMessage = messageObject['laterHalfMessage'];
        this.showTextWithHyperLink = true;
      }
      this.moveToTop();
    },
    displayModal(reservationInfo) {
      this.reservationInfo = reservationInfo;
      this.needsModal = true;
    },
    makeShowPlainTextFlagFalse() {
      this.showPlainText = false;
    },
    makeShowTextWithHyperLinkFlagFalse() {
      this.showTextWithHyperLink = false;
    },
    makeNeedsModalFlagFalse() {
      this.reservationInfo = '';
      this.needsModal = false;
    },
    moveToTop() {
      const duration = 250;
      const interval = 25;
      const step = -window.scrollY / Math.ceil(duration / interval); // 1回に移動する距離
      const timer = setInterval(() => {
        window.scrollBy(0, step);
        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    }
  }
};
</script>

<style scoped>
.reserve-facility-form {
  text-align: left;
}
</style>