<template>
  <div>
    <p class="mb-3">
      <b-spinner class="align-middle" />
      <strong>Loading...</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  data() {
    return {};
  }
};
</script>

<style scoped></style>
