<template>
  <div class="header">
    <b-navbar type="light" variant="light">
      <b-navbar-nav>
        <img src="@/assets/howlive.png" width="170" height="" />
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
.header {
  padding-bottom: 30px;
}

.navbar-nav {
  margin: 0 auto;
}
</style>
