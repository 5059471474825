<template>
  <b-container>
    <b-alert
      :show="dismissCountDown"
      :variant="messageType"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      {{firstHalfMessage}}
      <a :href="hyperlink" style="color:#ff8c00; text-decoration:none;">{{hyperlinkMessage}}</a>
      {{laterHalfMessage}}
    </b-alert>
  </b-container>
</template>

<script>
export default {
  name: 'FlashMessageWithHyperlink',
  components: {},
  props: {
    showAlert: {
      type: Boolean,
      default: false
    },
    messageType: {
      type: String,
      default: ''
    },
    firstHalfMessage: {
      type: String,
      default: ''
    },
    hyperlink: {
      type: String,
      default: ''
    },
    hyperlinkMessage: {
      type: String,
      default: ''
    },
    laterHalfMessage: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      // クリック用のリンクを表示するためplaintext用のflashより表示時間を長めに設定
      dismissSecs: 15,
      dismissCountDown: 0
    };
  },
  watch: {
    showAlert(showAlert) {
      if (showAlert) {
        this.dismissCountDown = this.dismissSecs
      }
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown == 0) {
        this.$emit('hideFlashMessageWithHyperLink');
      }
    }
  }
};
</script>

<style scoped></style>
