<template>
  <div class="viewing-user-profile">
    <b-container>
      <b-alert v-model="showUserInfoApiErrorAlert" variant="danger" dismissible>
        システムエラーによりユーザー情報の取得に失敗しました。お手数ですが、しばらく時間を置いてから、再度操作をしていただけますようお願いいたします。
      </b-alert>
      <b-alert v-model="showUserInfoApiNotFoundAlert" variant="danger" dismissible>
        ユーザー情報が登録されていません。LINEのSignUpメニューから利用登録を済ませてからご利用ください。
      </b-alert>
      <div>
        <Spinner v-if="isBusy" />
        <b-table v-if="!isBusy" :items="profileItems" :fields="profileFields" bordered head-variant="light" />
        <div class="mt-4 text-center">
          <p>
            以下の「変更する」ボタンで<br />
            メールアドレスと電話番号の変更が可能です。
          </p>
          <p>
            上記2項目以外の変更・退会を<br />
            ご希望の方は<a href="https://docs.google.com/forms/d/e/1FAIpQLSdrjeW4J6N-s5dU5VCG8gB9tIRGgbZZJb-1mgla75w34b4Wkw/viewform" style="color: #ff8c00; text-decoration: none">こちら</a>のフォーム<br />
            またはhowlive事務局 staff@howlive.jp まで<br />メールにてご連絡ください。
          </p>
        </div>
        <div class="row justify-content-center">
          <b-button
            class="text-light"
            variant="primary"
            @click="moveModifyingMailAddressPage"
          >変更する</b-button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { getUser } from '../api';
import liff from '@line/liff';
import Spinner from '../components/Spinner.vue';

export default {
  components: { Spinner },
  data() {
    return {
      isBusy: true,
      showUserInfoApiErrorAlert: false,
      showUserInfoApiNotFoundAlert: false,
      accessToken: '',
      phoneNumber: null,
      email: null,
      profileFields: [
        {key: 'item_name', label: '項目名', sortable: false},
        {key: 'item_value', label: '値', sortable: false}
      ],
      profileItems: []
    };
  },
  async created() {
    try {
      this.isBusy = true;
      await liff.init({
        liffId: process.env.VUE_APP_LIFF_ID,
        withLoginOnExternalBrowser: false
      });
      this.accessToken = await this.getLineAccessToken();
      const response = await getUser(this.accessToken);
      if (Object.keys(response.data).length == 0) {
        this.showUserInfoApiNotFoundAlert = true;
      }

      if (response.status != 200) {
        this.showUserInfoApiErrorAlert = true;
      }

      this.profileItems.push({
        item_name: '氏名',
        item_value: response.data.user_name
      });

      this.email = response.data.email;
      this.profileItems.push({
        item_name: 'メールアドレス',
        item_value: response.data.email
      });

      this.phoneNumber = response.data.telephone_number;
      this.profileItems.push({
        item_name: '電話番号',
        item_value: response.data.telephone_number
      });

      this.profileItems.push({
        item_name: '会員種別',
        item_value: response.data.membership
      });

      this.profileItems.push({
        item_name: '料金プラン名',
        item_value: response.data.plan_name
      });

      if ('company_name' in response.data) {
        this.profileItems.push({
          item_name: '企業名',
          item_value: response.data.company_name
        });
      }
    } catch (err) {
      this.showUserInfoApiErrorAlert = true;
    } finally {
      this.isBusy = false;
    }
  },
  methods: {
    async getLineAccessToken() {
      const accessToken = liff.getAccessToken();
      return accessToken;
    },
    moveModifyingMailAddressPage() {
      this.$router.push({ 
        name: 'edit-user-profile',
        params: {
          email: this.email,
          phoneNumber: this.phoneNumber
        }
      });
    }
  }
};
</script>

<style scoped></style>
