<template>
  <b-form>
    <Spinner v-if="isGettingStoreInfoBusy" />
    <p v-if="!isGettingStoreInfoBusy && (targetFacility == '' || reservationDate == '')">設備と利用日をお選びください。</p>
    <b-form-group v-if="!isGettingStoreInfoBusy" class="mb-4" label="店舗名:" label-for="storeNameInput">
      <b-form-input
        v-model="storeName"
        disabled
        label="storeNameInput"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      v-if="reservationType == 'facility' && !isGettingStoreInfoBusy"
      label="会議室・フォンブースを選択:"
      label-for="facilitySelect"
    >
      <b-form-select
        id="facilitySelect"
        v-model="targetFacility"
        :options="facilityOptions"
        @change="showAvilableTimes"
      >
        <template #first>
          <b-form-select-option value="" disabled>設備を選択してください</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    <label v-if="reservationType == 'facility' && !isGettingStoreInfoBusy" for="reservationDatePicker">
      利用日:
    </label>
    <b-form-datepicker
      v-if="reservationType == 'facility' && !isGettingStoreInfoBusy"
      id="reservationDatePicker"
      v-model="reservationDate"
      :date-disabled-fn="weekendDisabled"
      :max="ninetyDaysLater"
      :min="todayDate"
      class="mb-4"
      locale="jp"
      label-no-date-selected="日付を選択してください"
      @input="showAvilableTimes"
    >
    </b-form-datepicker>
    <Spinner v-if="isGettingAvailableTimeBusy" />
    <div v-if="pickingTimeFlag">
      <b-form-group
        label="選択時間のフィルタ（任意） ※"
        label-for="fromTimeSelector"
      >
        <b-form-select
          id="fromTimeSelector"
          v-model="selectedFromTime"
          :options="fromTimeOptions"
          :disabled="unselectableFlag"
          @change="filterTimes"
        >
        </b-form-select>
      </b-form-group>
      <label class="mb-4 small text-muted">
        ※ 予約枠の表示を選択した時間以降に限定することができます
      </label>

      <b-form-group
        label="時間選択のショートカットボタン（任意） ※"
        label-for="shortcutButton"
      >
        <b-form-checkbox
          id="shortcutButton"
          v-model="allDayOption"
          switch
          @change="pickingTimeQuickly"
        >
          終日利用
        </b-form-checkbox>
      </b-form-group>
      <label v-show="pickingTimeFlag" class="mb-4 small text-muted">※
        有効にすると直近の予約開始可能時刻（時間のフィルタを実施した場合は、フィルタ以降の時刻）から予約できる最長の時間枠を予約することができます。席数が満席の時間枠が途中にない限りは閉店時間まで予約します
      </label>
      <div class="mb-5">
        <p>{{ timePickerMessage }}</p>
        <Spinner v-if="isConfirmingReservationBusy" />
        <h6 v-if="reservationStartTime">
          ■利用開始時間：{{ reservationStartTime }}
        </h6>
        <h6 v-if="reservationEndTime">
          ■利用終了時間：{{ reservationEndTime }}
        </h6>
        <b-button
          v-if="reservationStartTime && !allDayOption"
          class="mt-1 text-light"
          size="sm"
          variant="warning"
          @click="resetPickingTime"
        >
          選択時間のリセット
        </b-button>
      </div>
      <div v-for="time in formattedAvailableTimes" :key="time.time">
        <div class="picking-box d-flex justify-content-around">
          <div v-show="pickingTimeFlag" class="border-top w-50">
            <p class="picking-time h6 mt-1">{{ time.time }}</p>
          </div>
          <b-button
            class="py-2 w-100 rounded-0"
            btn-block
            :pressed="time.active"
            :variant="time.color"
            :disabled="time.disabled"
            :value="time.time"
            @click="selectStayingTime"
          >
            {{ time.symbol }}
          </b-button>
        </div>
      </div>
      <div class="picking-close-box d-flex justify-content-around">
        <div class="border-top w-50">
          <p class="picking-time h6 mt-1">{{ closingTime }}</p>
        </div>
        <div class="h-25 w-50"></div>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  getStore,
  getFacilityAvailableTime,
  confirmReservation
} from '../api';
import Spinner from '../components/Spinner.vue';

export default {
  components: { Spinner },
  props: {
    reservationType: {
      type: String,
      default: ''
    },
    accessToken: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isGettingStoreInfoBusy: false,
      isGettingAvailableTimeBusy: false,
      isConfirmingReservationBusy: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      errorMsg: '',
      storeName: '',
      weekdayOpen: '',
      weekdayClose: '',
      holidayOpen: '',
      holidayClose: '',
      todayDate: '',
      isClosedforHoliday: false,
      facilitiesObject: {},
      facilityOptions: [],
      membership: '',
      targetFacility: '',
      reservationDate: '',
      facilityMinutes: 15,
      facilityType: '',
      rawAvailableTimes: [],
      lastReservationStartTime: '',
      formattedAvailableTimes: [],
      pickingTimeFlag: false,
      selectedFromTime: '',
      unselectableFlag: false,
      allDayOption: false,
      timePickerMessage: '利用開始時間を選択してください（青色の枠のみ選択可能です）',
      closeTime: '',
      reservationStartTime: '',
      reservationEndTime: '',
      usageFee: 0
    };
  },
  computed: {
    // 予約日時が平日か土日かによって営業時間の判定等に差異が生じるため参考に利用
    dayType() {
      const todayDate = new Date(this.todayDate);
      let dayType = ''
      const day = todayDate.getDay()
      if ([1, 2, 3, 4, 5].includes(day)) {
        dayType = 'weekday'
      } else if ([0, 6].includes(day)) {
        dayType = 'weekend'
      }
      return dayType
    },
    // 24時間営業施設の設備予約時のみ予約指定日の翌日の日付を利用する可能性がある。深夜時間帯（~00:00）予約実施時に利用
    nextDay() {
      const referenceDate = new Date(this.reservationDate);
      const targetDate = referenceDate.setDate(referenceDate.getDate() + 1);
      return this.getDateWithYmdFormat(new Date(targetDate));
    },
    // 設備予約が可能な日時は現在日時から90日後までという制約がある。当該制約を実現するために利用
    ninetyDaysLater() {
      const todayDate = new Date(this.todayDate);
      const targetDate = todayDate.setDate(todayDate.getDate() + 90);
      return this.getDateWithYmdFormat(new Date(targetDate));
    },
    lastAvailableTime() {
      let lastTimeObject = this.rawAvailableTimes[this.rawAvailableTimes.length - 1];
      return lastTimeObject['time'];
    },
    // 予約枠用カードを特定の時間以降に絞り込むためのフォームの選択肢として利用される
    fromTimeOptions() {
      let fromTimeOptions = [];
      try {
        const targetDate = new Date(this.reservationDate);
        const currentDate = new Date(
          Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
        );
        const targetDateYMD = Number(
          String(targetDate.getFullYear()).padStart(4, '0')
          + String(targetDate.getMonth()).padStart(2, '0')
          + String(targetDate.getDate()).padStart(2, '0')
        );
        const currentDateYMD = Number(
          String(currentDate.getFullYear()).padStart(4, '0')
          + String(currentDate.getMonth()).padStart(2, '0')
          + String(currentDate.getDate()).padStart(2, '0')
        );
        let startHour = parseInt(this.openingTime.split(':')[0]);
        const endHour = parseInt(this.closingTime.split(':')[0]);
        if (currentDateYMD > targetDateYMD) {
          this.$emit(
            'needsFlashMessage',
            'plain',
            'error',
            { message : '指定の日時は予約受付期間外となります。事前予約は本日から90日以内の日時のみ受け付けております。'}
          );
          throw new Error('out of scope');
        } else if (targetDateYMD == currentDateYMD) {
          if (endHour <= currentDate.getHours()) {
            let errorMessage = '';
            if (this.reservationType == 'dropin') {
              errorMessage = 'ドロップイン受付時間外のため予約の受付ができません。後日のご利用をお待ちしております。';
            } else if (this.reservationType == 'facility') {
              errorMessage = '本日は設備予約時間外のため予約の受付ができません。大変申し訳ありませんが後日のご利用をご検討ください。';
            }
            this.$emit('needsFlashMessage', 'plain', 'error', { message : errorMessage });
            throw new Error('out of scope');
          }
          startHour = currentDate.getHours() + 1;
        }
        // endHourの値をそのまま使うと、例えば20時閉店の店舗で20時以降に絞るフィルタが表示できてしまう（この場合19:00~を最後のフィルタとしたい）
        for (let i = startHour; i <= endHour - 1; i++) {
          fromTimeOptions.push({
            value: i + ':00',
            text: String(i).padStart(2, '0') + ':00~'
          });
        }
      } catch (error) {
        console.log(error.message);
      }
      return fromTimeOptions;
    },
    openingTime() {
      let openingTime = '';
      if (this.dayType == 'weekday') {
        openingTime = this.weekdayOpen
      } else if (this.dayType == 'weekend') {
        openingTime = this.holidayOpen
      }
      return openingTime;
    },
    closingTime() {
      let closingTime = '';
      if (this.dayType == 'weekday') {
        closingTime = this.weekdayClose
      } else if (this.dayType == 'weekend') {
        closingTime = this.holidayClose
      }
      return closingTime;
    },
  },
  watch: {
    async accessToken() {
      if (!this.accessToken) {
        return;
      }
      try {
        this.isGettingStoreInfoBusy = true;
        const res = await getStore(this.accessToken, this.$route.query.store_id);
        this.storeName = res['data']['store_name'];
        this.weekdayOpen = res['data']['weekday_open'];
        // 本ページの関数処理上、終日営業店舗の終了時間は00:00ではなく24:00で表現しないと不都合が生じるため必要時に修正を実施
        this.weekdayClose = 
          (res['data']['weekday_close'] == '00:00')
            ? '24:00'
            : res['data']['weekday_close']
        // holiday_openが空の場合 = 非正会員に施設利用・ドロップイン利用を許可していないケースのため当該ケース判別用のboolean変数を変更
        if (res['data']['holiday_open'] === '') {
          this.isClosedforHoliday = true 
        } else {
          this.holidayOpen = res['data']['holiday_open']
          this.holidayClose = 
            (res['data']['holiday_close'] == '00:00')
              ? '24:00'
              : res['data']['holiday_close']
        }
        if (this.reservationType == 'dropin') {
          this.reservationDate = this.getDateWithYmdFormat(new Date());
          this.facilityMinutes = 15;
          let facilityArray = res['data']['facilities'].filter((facility) => {
            return facility['facility_type'] == 'coworking-space';
          })
          // 予約にあたり設備IDが必要であるためドロップイン用ID（各店舗に1つ）を保存しておく
          this.targetFacility = facilityArray[0]['facility_id']
          this.invokeAvailableTimeApiAndReflectTheResult();
        } else if (this.reservationType == 'facility') {
          this.assignVariablesRelatedFacility(res['data']['facilities']);
        }
      } catch (err) {
        this.$emit(
          'needsFlashMessage',
          'plain',
          'error',
          { message : 'システムエラーにより店舗情報の取得に失敗しました。お手数ですが、しばらく時間を置いてから、再度操作をしていただけますようお願いいたします。'}
        );
        return;
      } finally {
        this.isGettingStoreInfoBusy = false;
      }
    }
  },
  async created() {
    this.todayDate = this.getDateWithYmdFormat(new Date());
  },
  methods: {
    getDateWithYmdFormat(dt) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth()+1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },
    // 土日に一般公開しない店舗がある。該当店舗 & ユーザーが非正会員の場合に土日を予約日時として選択できないようにする
    weekendDisabled(ymd, date) {
      if (this.isClosedforHoliday) {
        const weekday = date.getDay()
        return weekday === 0 || weekday === 6
      }
    },
    assignVariablesRelatedFacility(facilities) {
      facilities.sort((a, b) => {
        if (a['facility_display_order'] > b['facility_display_order']) {
          return 1;
        } else {
          return -1;
        }
      });
      let facilitiesObject = new Object();
      for (const facility of facilities) {
        // 設備一覧情報のうちドロップイン情報は設備予約の選択肢外となるため除外している
        if (facility['facility_type'] == 'coworking-space') {
          continue;
        }
        facilitiesObject[facility['facility_id']] = facility;
        // 設備予約の設備選択肢用のdata
        this.facilityOptions.push({
          value: facility['facility_id'],
          text: facility['facility_name']
        });
      }
      // 設備のIDをキーとして設備の各種情報を格納しておくObject（後続処理で参照する）
      this.facilitiesObject = facilitiesObject;
    },
    async invokeAvailableTimeApiAndReflectTheResult() {
      try {
        this.isGettingAvailableTimeBusy = true;
        const reservationDatetime = 
          (this.reservationDate == this.todayDate)
            ? this.getIso8601String(true)
            : this.getIso8601String(false, this.reservationDate, this.openingTime)
        const res = await getFacilityAvailableTime(this.accessToken, {
          facility_id: this.targetFacility,
          start_datetime: reservationDatetime
        });
        this.rawAvailableTimes = res['data']['times'];
        // 店舗利用に制限のあるユーザーの場合は予約開始の締め切り時間がAPIで返されるため専用変数に格納する
        if (res['data']['last_start'] != 'None') {
          this.lastReservationStartTime = res['data']['last_start'];
        } else {
          this.lastReservationStartTime = this.closingTime;
        }
        this.insertAdditionalKeysIntoTimeObjects();
        this.pickingTimeFlag = true;
      } catch (err) {
        console.log(err);
        const errorReason = err.response?.data.error || err.message;
        let errorMessage = '';
        let hyperlinkMessageObj = {};
        switch (errorReason) {
        case 'no_reservation_space':
          if (this.reservationType == 'dropin') {
            errorMessage = '本日は利用者が定員に達したため施設の利用ができません。大変申し訳ありませんが後日のご利用をご検討ください。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '指定の日時はどの時間帯も設備が予約されており予約できません。大変申し訳ありませんが後日のご利用をご検討ください。';
          }
          break;
        case 'no_acceptable_time':
          if (this.reservationType == 'dropin') {
            errorMessage = 'ドロップイン受付時間外のため予約の受付ができません。後日のご利用をお待ちしております。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '本日は設備予約時間外のため予約の受付ができません。大変申し訳ありませんが後日のご利用をご検討ください。';
          }
          break;
        case 'store_closed':
          if (this.reservationType == 'dropin') {
            errorMessage = '本日は休業日のためドロップイン予約の受付ができません。後日のご利用をお待ちしております。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '指定の日時は店舗休業日のため設備の予約ができません。大変申し訳ありませんが後日のご利用をご検討ください。';
          }
          break;
        case 'no_acceptable_date':
          errorMessage = '指定の日時は予約受付期間外となります。事前予約は本日から90日以内の日時のみ受け付けております。';
          break;
        case 'no_acceptable_plan':
          errorMessage = 'お客様はドロップイン予約が不要な会員種別です。施設の利用に当たっては事前に配布したPINコードをご利用ください。';
          break;
        case 'after_cancellation_plan':
          hyperlinkMessageObj['firstHalfMessage'] = '指定の日時はお客様の契約期間外となります。当該日時での予約を希望される場合は、契約更新の希望有無を書き添えて';
          hyperlinkMessageObj['hyperlinkMessage'] = 'こちら';
          hyperlinkMessageObj['hyperlink'] = 'https://docs.google.com/forms/d/e/1FAIpQLSdrjeW4J6N-s5dU5VCG8gB9tIRGgbZZJb-1mgla75w34b4Wkw/viewform';
          hyperlinkMessageObj['laterHalfMessage'] = 'のフォームまたはhowlive事務局 staff@howlive.jp までメールにてご連絡ください。';
          break;
        case 'invalid_plan':
          hyperlinkMessageObj['firstHalfMessage'] = '現在お客様の契約プランは失効しております。当該日時での予約を希望される場合は、契約更新の希望有無を書き添えて';
          hyperlinkMessageObj['hyperlinkMessage'] = 'こちら';
          hyperlinkMessageObj['hyperlink'] = 'https://docs.google.com/forms/d/e/1FAIpQLSdrjeW4J6N-s5dU5VCG8gB9tIRGgbZZJb-1mgla75w34b4Wkw/viewform';
          hyperlinkMessageObj['laterHalfMessage'] = 'のフォームまたはhowlive事務局 staff@howlive.jp までメールにてご連絡ください。';
          break;
        default:
          errorMessage = 'システムエラーにより店舗情報の取得に失敗しました。お手数ですが、しばらく時間を置いてから、再度操作をしていただけますようお願いいたします。';
        }
        if (['after_cancellation_plan', 'invalid_plan'].includes(errorReason)) {
          this.$emit('needsFlashMessage', 'withHyperlink', 'error', hyperlinkMessageObj);
        } else {
          this.$emit('needsFlashMessage', 'plain', 'error', { message: errorMessage });
        }
      } finally {
        this.isGettingAvailableTimeBusy = false;
      }
    },
    // 利用可能時間取得や予約用APIでISO形式・JSTの日時情報を要求されるため、当該情報を生成するための関数
    getIso8601String(needsNowDate = false, dateStr = '', timeStr = '') {
      let Iso8601Str = '';
      if (needsNowDate) {
        const dt = new Date(Date.now());
        const jdt = new Date(dt.getTime() + 9 * 60 * 60 * 1000);  // UTC => JST変換
        Iso8601Str = jdt.toISOString().split('Z')[0].slice(0, -4)  + '+09:00'; // APIリクエスト上ミリ秒情報が不要のためsliceで削除
      } else {
        Iso8601Str = dateStr + 'T' + timeStr + '+09:00';
      }
      return Iso8601Str
    },
    // 利用可能時間取得APIから取得できる時刻情報だけでは描画上の不足があるためキーを追加する。利用時間枠の表示のリセット用にも利用。
    insertAdditionalKeysIntoTimeObjects() {
      this.formattedAvailableTimes = [];
      const tmpLastTime = this.lastReservationStartTime.split(':');
      for (const availableTime of this.rawAvailableTimes) {
        let record = {
          time: availableTime['time'],
          availability_15min: availableTime['availability_15min'],
          availability_30min: availableTime['availability_30min'],
          active: false,
          symbol: availableTime['availability_15min'] ? '◯' : '×'
        };
        const tmpTime = availableTime['time'].split(':');
        // tmpLastTime=各予約枠の時間が予約開始の締め切り時間以降か否か判定
        const isTmpTimeAfterReservationLastTime = 
          parseInt(tmpLastTime[0].padStart(2, '0') + tmpLastTime[1].padStart(2, '0')) 
          <
          parseInt(tmpTime[0].padStart(2, '0') + tmpTime[1].padStart(2, '0'));
        // 予約開始の締め切り時間以降の時間枠については無効化する
        if (isTmpTimeAfterReservationLastTime) {
          record['color'] = 'outline-secondary';
        } else if (this.facilityMinutes == 15) {
          if (availableTime['availability_15min']) {
            record['color'] = 'outline-info';
          } else {
            record['color'] = 'outline-danger';
          }
        } else if (this.facilityMinutes == 30) {
          if (availableTime['availability_30min']) {
            record['color'] = 'outline-info';
          } else if (availableTime['availability_15min']) {
            record['color'] = 'outline-secondary';
          } else {
            record['color'] = 'outline-danger';
          }
        }
        if (record['color'] == 'outline-info') {
          record['disabled'] = false;
        } else {
          record['disabled'] = true;
        }
        this.formattedAvailableTimes.push(record);
      }
    },
    // 利用設備・日時両方の選択が完了した時点で、当該日時の設備の予約可能時間枠についての情報を取得
    async showAvilableTimes() {
      if (this.targetFacility && this.reservationDate) {
        this.facilityMinutes = this.facilitiesObject[this.targetFacility]['facility_minute'];
        this.facilityType = this.facilitiesObject[this.targetFacility]['facility_type'];
        this.invokeAvailableTimeApiAndReflectTheResult()
      }
    },
    pickingTimeQuickly() {
      if (this.allDayOption == true) {
        let counter = 0;
        for (const availableTime of this.formattedAvailableTimes) {
          availableTime.disabled = true;
          if (counter == 0) {
            if (
              this.facilityMinutes == 15 &&
              availableTime.availability_15min
            ) {
              this.reservationStartTime = availableTime.time;
              availableTime.color = 'info';
              counter += 1;
              if (!availableTime.availability_30min) {
                this.reservationEndTime = this.add15miniutes(
                  availableTime.time
                );
                availableTime.color = 'info';
                counter += 1;
              }
            } else if (
              this.facilityMinutes == 30 &&
              availableTime.availability_30min
            ) {
              this.reservationStartTime = availableTime.time;
              availableTime.color = 'info';
              counter += 1;
            } else {
              availableTime.color = 'secondary';
            }
          } else if (counter == 1) {
            if (
              this.facilityMinutes == 15 &&
              !availableTime.availability_15min
            ) {
              this.reservationEndTime = availableTime.time;
              availableTime.color = 'secondary';
              counter += 1;
            } else if (
              this.facilityMinutes == 30 &&
              !availableTime.availability_30min
            ) {
              if (this.isTimeAvilableForEndTime(availableTime.time)) {
                this.reservationEndTime = this.add15miniutes(
                  availableTime.time
                );
                availableTime.color = 'info';
              } else {
                this.reservationEndTime = availableTime.time;
                availableTime.color = 'secondary';
              }
              counter += 1;
            } else {
              availableTime.color = 'info';
            }
          } else {
            availableTime.color = 'secondary';
          }
        }
        if (!this.reservationEndTime) {
          const lastTime = this.formattedAvailableTimes.slice(-1)[0];
          this.reservationEndTime = this.add15miniutes(lastTime.time);
        }
        this.unselectableFlag = true;
        this.timePickerMessage = '利用時間の選択が完了しました。';
        this.showPromptForReserving();
      } else if (this.allDayOption == false) {
        this.resetPickingTime();
      }
    },
    add15miniutes(time) {
      const toTimeHours = parseInt(time.slice(0, 2));
      const toTimeMinutes = parseInt(time.slice(-2));
      let date = new Date();
      date.setHours(toTimeHours);
      date.setMinutes(toTimeMinutes + 15);
      date.setSeconds(0);
      let timeAdded15miniutes =
        ('0' + date.getHours()).substr(-2) +
        ':' +
        ('0' + date.getMinutes()).substr(-2);
      if (timeAdded15miniutes == '00:00') {
        timeAdded15miniutes = '24:00';
      }
      return timeAdded15miniutes;
    },
    resetPickingTime() {
      this.insertAdditionalKeysIntoTimeObjects();
      this.timePickerMessage = '利用開始時間を選択してください（青色の枠のみ選択可能です）';
      this.reservationStartTime = '';
      this.reservationEndTime = '';
      this.unselectableFlag = false;
      this.allDayOption = false;
      this.usageFee = 0;
    },
    filterTimes() {
      this.insertAdditionalKeysIntoTimeObjects();
      let filteredTimes = [...this.formattedAvailableTimes];
      const zfilledSelectedFromTime = this.selectedFromTime.padStart(5, '0')
      for (const [
        index,
        availableTime
      ] of this.formattedAvailableTimes.entries()) {
        if (zfilledSelectedFromTime == availableTime.time) {
          break;
        } else {
          delete filteredTimes[index];
        }
      }
      filteredTimes = filteredTimes.filter(Boolean);
      this.formattedAvailableTimes = [...filteredTimes];
    },
    selectStayingTime(event) {
      // 時間枠の初回クリック（=利用開始時間選択）時
      if (this.reservationStartTime === '') {
        this.reservationStartTime = event.target.value;
        this.lockTimeButtons(event.target.value);
        this.unselectableFlag = true;
        this.timePickerMessage = '利用終了時間を選択してください（青色の枠のみ選択可能です）。15分のみ予約する場合は初回に選択した枠と同じ枠を選択してください';
      // 時間枠の二回目クリック（=利用終了時間選択）時
      } else if (this.reservationEndTime === '') {
        this.reservationEndTime = this.add15miniutes(event.target.value);
        this.finishChoosingTime();
      }
    },
    // 利用開始時間選択後、当該時間より前の時間枠等 利用終了時間として選択するのが不適な枠をグレーアウトする
    lockTimeButtons(fromTime) {
      let counter = 0;
      for (const availableTime of this.formattedAvailableTimes) {
        if (fromTime == availableTime.time) {
          counter += 1;
          availableTime.color = 'info';
          availableTime.avtive = true;
          if (this.facilityMinutes == 30) {
            availableTime.disabled = true;
          }
        } else if (counter == 1) {
          const isTimeEndTime = this.lastAvailableTime == availableTime.time;
          if (this.facilityMinutes == 15 && availableTime.availability_15min) {
            availableTime.color = 'outline-info';
            availableTime.disabled = false;
          // 基本的にはavailability_30minがtrueのものを予約終了時間の選択可能候補とするが、最後の時間枠のみavailability_15minがtrueであれば候補とする
          } else if (
            this.facilityMinutes == 30 &&
            (availableTime.availability_30min || (isTimeEndTime && availableTime.availability_15min))
          ) {
            if (this.isTimeAvilableForEndTime(availableTime.time)) {
              availableTime.color = 'outline-info';
              availableTime.disabled = false;
            } else {
              availableTime.color = 'outline-secondary';
              availableTime.disabled = true;
            }
          } else {
            availableTime.color = 'secondary';
            availableTime.disabled = true;
            counter += 1;
          }
        } else {
          availableTime.color = 'secondary';
          availableTime.disabled = true;
        }
      }
    },
    isTimeAvilableForEndTime(time) {
      const fromTimeMinutes = parseInt(this.reservationStartTime.slice(-2));
      const toTimeMinutes = parseInt(time.slice(-2));
      if ([0, 30].includes(fromTimeMinutes)) {
        return [15, 45].includes(toTimeMinutes) ? true : false;
      } else if ([15, 45].includes(fromTimeMinutes)) {
        return [0, 30].includes(toTimeMinutes) ? true : false;
      }
      return false;
    },
    finishChoosingTime() {
      let counter = 0;
      for (const availableTime of this.formattedAvailableTimes) {
        availableTime.disabled = true;
        availableTime.active = true;
        if (this.reservationStartTime == availableTime.time) {
          availableTime.color = 'info';
          counter += 1;
        } else if (this.reservationEndTime == availableTime.time) {
          availableTime.color = 'secondary';
          counter += 1;
        // counterが0の場合は予約開始時間として選択したブロックに到達していない
        } else if (counter == 0) {
          availableTime.color = 'secondary';
        // counterが1の場合は予約開始時間として選択したブロックと終了時間として選択したブロックの間
        } else if (counter == 1) {
          availableTime.color = 'info';
        // else（counterが2以上）の場合は予約終了時間を過ぎている
        } else {
          availableTime.color = 'secondary';
        }
      }
      this.showPromptForReserving();
    },
    async showPromptForReserving() {
      try {
        this.timePickerMessage = '施設状況の確認中・料金の計算中です。';
        this.isConfirmingReservationBusy = true;
        const isoStartTime = this.getIso8601String(false, this.reservationDate, this.reservationStartTime);
        let isoEndTime = '';
        if (this.reservationEndTime == '24:00') {
          isoEndTime = this.getIso8601String(false, this.nextDay, '00:00');
        } else {
          isoEndTime = this.getIso8601String(false, this.reservationDate, this.reservationEndTime);
        }
        const res = await confirmReservation(this.accessToken, {
          facility_id: this.targetFacility,
          start_datetime: isoStartTime,
          end_datetime: isoEndTime
        });
        this.usageFee = res['data']['usage_fee'];
        const facilityName = 
          (this.reservationType == 'facility') 
            ? this.facilitiesObject[this.targetFacility]['facility_name'] 
            : ''
        this.$emit('needsReservationModal', {
          storeName: this.storeName,
          facilityName: facilityName,
          targetFacility: this.targetFacility,
          date: this.reservationDate,
          startTime: this.reservationStartTime,
          endTime: this.reservationEndTime,
          isoStartTime: isoStartTime,
          isoEndTime: isoEndTime,
          usageFee: this.usageFee
        });
      } catch (err) {
        console.log(err);
        const errorReason = err.response?.data.error || err.message;
        let errorMessage = '';
        let hyperlinkMessageObj = {};
        switch (errorReason) {
        case 'no_acceptable_time':
          if (this.reservationType == 'dropin') {
            errorMessage = 'ドロップイン受付時間外のため予約の受付ができません。後日のご利用をお待ちしております。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '本日は設備予約時間外のため予約の受付ができません。大変申し訳ありませんが後日のご利用をご検討ください。';
          }
          break;
        case 'no_acceptable_plan':
          errorMessage = 'お客様はドロップイン予約が不要な会員種別です。施設の利用に当たっては事前に配布したPINコードをご利用ください。';
          break;
        case 'no_reservation_space':
          if (this.reservationType == 'dropin') {
            errorMessage = '利用者が定員に達したため指定の時間帯が予約できなくなりました。お手数ですが再度時間帯を指定してください。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '指定の時間帯について他のユーザーにより設備が予約されてしまいました。お手数ですが再度時間帯を指定してください。';
          }
          break;
        case 'after_cancellation_plan':
          hyperlinkMessageObj['firstHalfMessage'] = '指定の日時はお客様の契約期間外となります。当該日時での予約を希望される場合は、契約更新の希望有無を書き添えて';
          hyperlinkMessageObj['hyperlinkMessage'] = 'こちら';
          hyperlinkMessageObj['hyperlink'] = 'https://docs.google.com/forms/d/e/1FAIpQLSdrjeW4J6N-s5dU5VCG8gB9tIRGgbZZJb-1mgla75w34b4Wkw/viewform';
          hyperlinkMessageObj['laterHalfMessage'] = 'のフォームまたはhowlive事務局 staff@howlive.jp までメールにてご連絡ください。';
          break;
        case 'invalid_plan':
          hyperlinkMessageObj['firstHalfMessage'] = '現在お客様の契約プランは失効しております。当該日時での予約を希望される場合は、契約更新の希望有無を書き添えて';
          hyperlinkMessageObj['hyperlinkMessage'] = 'こちら';
          hyperlinkMessageObj['hyperlink'] = 'https://docs.google.com/forms/d/e/1FAIpQLSdrjeW4J6N-s5dU5VCG8gB9tIRGgbZZJb-1mgla75w34b4Wkw/viewform';
          hyperlinkMessageObj['laterHalfMessage'] = 'のフォームまたはhowlive事務局 staff@howlive.jp までメールにてご連絡ください。';
          break;
        default:
          errorMessage = 'システムエラーにより店舗情報の取得に失敗しました。お手数ですが、しばらく時間を置いてから、再度操作をしていただけますようお願いいたします。';
        }
        if (['after_cancellation_plan', 'invalid_plan'].includes(errorReason)) {
          this.$emit('needsFlashMessage', 'withHyperlink', 'error', hyperlinkMessageObj);
        } else {
          this.$emit('needsFlashMessage', 'plain', 'error', { message: errorMessage });
        }
      } finally {
        this.timePickerMessage = '利用時間の選択が完了しました。';
        this.isConfirmingReservationBusy = false;
      }
    }
  }
};
</script>

<style scoped>
.picking-box {
  position:relative;
  top: 0;
}

.picking-close-box{
  position:relative;
  top: 0;
}

.picking-time {
  position: absolute; 
  top: -12px;
  left: 10%;
}
</style>

