<template>
  <div class="sign-up-form">
    <b-container>
      <b-alert
        v-model="showAlreadyRegistratedAlert"
        variant="danger"
        dismissible
        >既に利用登録されています。</b-alert
      >
      <b-alert v-model="showValidPlanAlert" variant="danger" dismissible
        >選択したご契約のプランを確認できませんでした。ご契約時のメールアドレスが入力されているかどうかご確認ください。</b-alert
      >
      <b-alert
        v-model="showValidWorkationPlanAlert"
        variant="danger"
        dismissible
        >選択した利用開始日及び利用終了日の予約を確認できませんでした。</b-alert
      >
      <b-alert
        v-model="showUnexpectedEmailFormatAlert"
        variant="danger"
        dismissible
      >
        メールアドレス欄に不正な値が入力されています。メールアドレスの形式で入力してください。またスペースが混入していないかも確認してください。
      </b-alert>
      <b-alert
        v-model="showUnexpectedPhoneNumberFormatAlert"
        variant="danger"
        dismissible
      >
        電話番号欄に不正な値が入力されています。数字以外の値を入力しないでください。またスペースが混入していないかも確認してください。
      </b-alert>
      <b-alert
        v-model="showSignUpSystemErrorAlert"
        variant="danger"
        dismissible
      >
        システムエラーが発生しました。お手数ですが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。
      </b-alert>
      <Spinner v-if="isBusy" />
      <b-form v-if="!showAlreadyRegistratedAlert && !isBusy" @submit.prevent="signUp">
        <b-form-group id="input-group-name" label="お名前：">
          <b-form-input
            id="input-name"
            v-model="name"
            label="お名前"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-phone"
          label="電話番号："
          description="ハイフンなしでご記入ください"
        >
          <b-form-input
            id="input-phone"
            v-model="tel"
            label="電話番号"
            type="tel"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-prefecture"
          label="お住まいの都道府県"
          description="日本国外にお住まいの方は、『日本国外』を選択してください。"
        >
          <b-form-select
            id="input-prefeccture"
            v-model="selectedPrefectures"
            :options="prefecturesList"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-age"
          label="年代"
          invalid-feedback="年代を選択してください"
        >
          <b-form-select
            id="input-age"
            v-model="selectedAge"
            :options="ageList"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-gender" label="性別：">
          <b-row>
            <b-col>
              <b-form-radio
                id="input-man"
                v-model="selectedGender"
                name="man"
                value="男性"
                >男性</b-form-radio
              >
            </b-col>
            <b-col>
              <b-form-radio
                id="input-woman"
                v-model="selectedGender"
                name="woman"
                value="女性"
                >女性</b-form-radio
              >
            </b-col>
            <b-col>
              <b-form-radio
                id="input-unknown"
                v-model="selectedGender"
                name="unknown"
                value="無回答"
                >無回答</b-form-radio
              >
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          id="input-group-job"
          label="ご職業"
          invalid-feedback="ご職業を選択してください"
        >
          <b-form-select
            id="input-job"
            v-model="selectedJob"
            :options="jobList"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-member-type" label="会員種別：">
          <b-row>
            <b-col cols="4">
              <b-form-radio
                id="input-non-member"
                v-model="selectedMemberType"
                name="non-member"
                value="drop-in"
                >非会員</b-form-radio
              >
            </b-col>
            <b-col cols="8">
              <b-form-radio
                id="input-non-member-workcation"
                v-model="selectedMemberType"
                name="non-member-workcation"
                value="workation"
                >非会員（ワーケーション）</b-form-radio
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-radio
                id="member"
                v-model="selectedMemberType"
                name="member"
                value="member"
                >会員</b-form-radio
              >
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          id="input-group-email"
          label="メールアドレス："
          :description="emailDescription"
        >
          <b-form-input
            id="input-email"
            v-model="email"
            label="メールアドレス"
            type="email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="selectedMemberType === 'workation'"
          id="input-group-start-date"
          label="利用開始日:"
        >
          <b-form-input
            id="input-start-date"
            v-model="startDate"
            label="利用開始日"
            type="date"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="selectedMemberType === 'workation'"
          id="input-group-end-date"
          label="利用終了日:"
        >
          <b-form-input
            v-model="endDate"
            label="利用終了日"
            type="date"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="selectedMemberType === 'member'"
          id="input-group-plan"
          label="プラン:"
        >
          <b-form-select
            id="input-plan"
            v-model="selectedPlan"
            :options="pranList"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          v-if="selectedMemberType === 'member'"
          id="input-group-company"
          label="登録企業名（個人の方はご契約者名）:"
        >
          <b-form-input
            id="input-campany"
            v-model="company"
            label="登録企業名"
          ></b-form-input>
        </b-form-group>

        <div class="row justify-content-center">
          <b-form-group>
            <b-form-checkbox-group
              id="checkboxes-group-terms-check"
              v-model="termsChecked"
            >
              <b-form-checkbox value="true">
                <terms-of-service></terms-of-service>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>

        <div class="row justify-content-center">
          <b-button
            class="mt-4 text-light"
            type="submit"
            variant="primary"
            :disabled="!isRequiredValid || signUpProcceing"
          >
            登録する
          </b-button>
        </div>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import Spinner from '../components/Spinner.vue';
import TermsOfService from '../components/TermsOfService.vue';
import { prefectures } from '../data/prefectures';
import { ages } from '../data/ages';
import { jobs } from '../data/jobs';
import { plans } from '../data/plans';
import liff from '@line/liff';
import { getUser, checkPlan, signUpTmp } from '../api';

class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}

export default {
  name: 'SignUp',
  components: {
    Spinner,
    TermsOfService
  },
  data() {
    return {
      isBusy: true,
      name: '',
      email: '',
      tel: '',
      prefecturesList: prefectures(),
      ageList: ages(),
      jobList: jobs(),
      pranList: plans(),
      selectedPrefectures: '沖縄県',
      selectedAge: '',
      selectedJob: '',
      selectedGender: '男性',
      selectedMemberType: 'drop-in',
      startDate: '',
      endDate: '',
      selectedPlan: '',
      company: '',
      termsChecked: [],
      userNumber: {},
      accessToken: '',
      showAlreadyRegistratedAlert: false,
      showValidPlanAlert: false,
      showValidWorkationPlanAlert: false,
      showSignUpSystemErrorAlert: false,
      showUnexpectedEmailFormatAlert: false,
      showUnexpectedPhoneNumberFormatAlert: false,
      signUpProcceing: false
    };
  },
  computed: {
    // 会員種別によって email 入力欄の説明文を変更する
    emailDescription() {
      let emailDescription;
      if (this.selectedMemberType == 'drop-in') {
        emailDescription =
          'ご入力されたメールアドレスは決済で使用するため、受信可能なメールアドレスをご入力ください。';
      }
      if (this.selectedMemberType == 'workation') {
        emailDescription =
          '事前予約した際のメールアドレスをご入力ください。また、当メールアドレスは会議室予約決済時にも使用します。';
      }
      if (this.selectedMemberType == 'member') {
        emailDescription = 'ご契約時のメールアドレスをご入力ください。';
      }
      return emailDescription;
    },
    // 共通必須項目チェック
    requiredValidation() {
      return {
        name: !!this.name,
        tel: !!this.tel,
        email: !!this.email,
        selectedPrefectures: !!this.selectedPrefectures,
        selectedAge: !!this.selectedAge,
        selectedJob: !!this.selectedJob,
        selectedGender: !!this.selectedGender,
        termsChecked: this.termsChecked.length > 0 ? true : false
      };
    },
    // ワーケーションプラン用必須項目チェック
    requiredWorkationValidation() {
      return {
        startDate: !!this.startDate,
        endDate: !!this.endDate
      };
    },
    // 会員用必須項目チェック
    requiredMemberValidation() {
      return {
        selectedPlan: !!this.selectedPlan,
        company: !!this.company
      };
    },
    // 必須項目チェック
    isRequiredValid() {
      let result = false;
      const validation = this.requiredValidation;
      const resultVal = Object.keys(validation).every((key) => validation[key]);

      if (this.selectedMemberType == 'drop-in') {
        result = resultVal;
      } else if (this.selectedMemberType == 'workation') {
        const workationVal = this.requiredWorkationValidation;
        const workationResultVal = Object.keys(workationVal).every(
          (key) => workationVal[key]
        );
        result = resultVal && workationResultVal;
      } else if (this.selectedMemberType == 'member') {
        const memberVal = this.requiredMemberValidation;
        const memberResultVal = Object.keys(memberVal).every(
          (key) => memberVal[key]
        );
        result = resultVal && memberResultVal;
      }

      return result;
    }
  },
  async created() {
    this.isBusy = true;
    
    // liff 初期化
    await liff.init({
      liffId: process.env.VUE_APP_LIFF_ID,
      withLoginOnExternalBrowser: false
    });
    this.accessToken = await this.getLineAccessToken();
    await this.isSignUp(this.accessToken);
    
    this.isBusy = false;
  },
  methods: {
    async getLineAccessToken() {
      const accessToken = liff.getAccessToken();
      return accessToken;
    },
    async isSignUp() {
      try {
        const res = await getUser(this.accessToken);

        // ユーザー情報が返却された場合は登録済みとみなす
        if (Object.keys(res.data).length) {
          this.showAlreadyRegistratedAlert = true;
        }
      } catch (err) {
        console.log(err);
        this.showSignUpSystemErrorAlert = true;
      }
    },
    async validPlan() {
      let planCode;
      if (this.selectedMemberType != 'member') {
        planCode = this.selectedMemberType;
      } else {
        planCode = this.selectedPlan;
      }

      let params = {
        email: this.email,
        plan_code: planCode
      };

      if (this.selectedMemberType == 'workation') {
        params['start_date'] = this.startDate;
        params['end_date'] = this.endDate;
      }
      try {
        const res = await checkPlan(params);
        const status = res.data['status'];

        // 入会中（join）の場合は、仮登録時に必要な値を利用者のプランに合わせて取得する
        if (status == 'join') {
          if (this.selectedMemberType == 'member') {
            this.userNumber['member_number'] = res.data['member_number'];
          }
          if (this.selectedPlan == 'corporation') {
            this.userNumber['corporation_user_number'] =
              res.data['corporation_user_number'];
          }
          if (this.selectedPlan == 'private-room') {
            this.userNumber['private_room_user_number'] =
              res.data['private_room_user_number'];
          }
          if (this.selectedMemberType == 'workation') {
            this.userNumber['general_user_number'] =
              res.data['general_user_number'];
          }
        } else {
          if (this.selectedMemberType == 'workation') {
            this.showValidWorkationPlanAlert = true;
            throw new ValidationError('not workation');
          } else {
            this.showValidPlanAlert = true;
            throw new ValidationError('not member');
          }
        }
      } catch (err) {
        if (err instanceof ValidationError) {
          throw new ValidationError(err.message);
        } else {
          throw new Error('api error: ' + err);
        }
      }
    },
    async signUp() {
      // 処理中はボタンを非活性
      this.signUpProcceing = true;

      // フラッシュメッセージの初期化
      this.showAlreadyRegistratedAlert = false;
      this.showValidPlanAlert = false;
      this.showValidWorkationPlanAlert = false;
      this.showSignUpSystemErrorAlert = false;
      this.showUnexpectedEmailFormatAlert = false;
      this.showUnexpectedPhoneNumberFormatAlert = false;

      try {
        const emailRegex =
          /^[A-Za-z0-9]{1}[A-Za-z0-9_.\-/+]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
        const phoneNumberRegex = /^(0{1}\d{9,10})$/;

        if (!emailRegex.test(this.email)) {
          this.showUnexpectedEmailFormatAlert = true;
        }

        if (!phoneNumberRegex.test(this.tel)) {
          this.showUnexpectedPhoneNumberFormatAlert = true;
        }

        if (
          [
            this.showUnexpectedEmailFormatAlert,
            this.showUnexpectedPhoneNumberFormatAlert
          ].some((x) => x)
        ) {
          throw new ValidationError('format error');
        }

        // ドロップイン以外はプランチェックを実施
        if (this.selectedMemberType != 'drop-in') {
          await this.validPlan();
        }
      } catch (err) { 
        if (err instanceof ValidationError) {
          console.log('validation error: ' + err.message);
          // ボタン非活性解除
          this.signUpProcceing = false;
          return;
        } else {
          this.showSignUpSystemErrorAlert = true;
          console.log(err.message);
        }
      }

      let planCode;
      if (this.selectedMemberType != 'member') {
        planCode = this.selectedMemberType;
      } else {
        planCode = this.selectedPlan;
      }

      let bodyCommon = {
        user_name: this.name,
        telephone_number: this.tel,
        prefecture_name: this.selectedPrefectures,
        age: this.selectedAge,
        gender: this.selectedGender,
        job: this.selectedJob,
        email: this.email,
        is_terms_checked: Boolean(this.termsChecked),
        plan_code: planCode
      };

      if (this.selectedMemberType == 'workation') {
        bodyCommon['start_date'] = this.startDate;
        bodyCommon['end_date'] = this.endDate;
      }
      if (this.selectedMemberType == 'member') {
        bodyCommon['company_name'] = this.company;
      }

      const body = { ...bodyCommon, ...this.userNumber };
      try {
        await signUpTmp(this.accessToken, body);

        // ボタン非活性解除
        this.signUpProcceing = false;

        this.$router.push({
          name: 'send-result',
          params: { email: this.email }
        });
      } catch (err) {
        console.log(err);
        this.showSignUpSystemErrorAlert = true;
      }
    }
  }
};
</script>

<style scoped>
.sign-up-form {
  text-align: left;
}
</style>
