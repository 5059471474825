<template>
  <div class="home"></div>
</template>

<script>
import liff from '@line/liff';
export default {
  data: () => {
    return {};
  },
  async created() {
    await liff.init({
      liffId: process.env.VUE_APP_LIFF_ID,
      withLoginOnExternalBrowser: Boolean(process.env.VUE_APP_LIFF_AUTO_LOGIN)
    });
  }
};
</script>

<style scoped>
h3 {
  font-size: 18px;
  font-weight: bold;
}
button {
  margin: 5px;
  /* width: 80vh; */
}
</style>