import Vue from 'vue';
import Router from 'vue-router';
import RegisterResult from './pages/RegisterResult';
import SendResult from './pages/SendResult';
import ReserveDropin from './pages/ReserveDropin';
import ReserveFacility from './pages/ReserveFacility';
import ViewUserProfile from './pages/ViewUserProfile';
import EditUserProfile from './pages/EditUserProfile';
import Home from './pages/Home';
import SignUp from './pages/SignUp.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { title: 'ホーム画面' }
    },
    {
      path: '/send-result',
      name: 'send-result',
      props: true,
      component: SendResult,
      meta: { title: 'メール送信結果画面' }
    },
    {
      path: '/register-result',
      name: 'register-result',
      props: true,
      component: RegisterResult,
      meta: { title: '利用登録結果画面' }
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: SignUp,
      meta: { title: '利用登録画面' }
    },
    {
      path: '/reserve/dropin',
      name: 'reserve-dropin',
      props: false,
      component: ReserveDropin,
      meta: { title: 'ドロップイン予約画面' }
    },
    {
      path: '/reserve/facility',
      name: 'reserve-facility',
      props: false,
      component: ReserveFacility,
      meta: { title: '施設予約画面' }
    },
    {
      path: '/user-profile',
      name: 'view-user-profile',
      props: false,
      component: ViewUserProfile,
      meta: { title: 'プロフィール画面' }
    },
    {
      path: '/edit/user-profile',
      name: 'edit-user-profile',
      props: true,
      component: EditUserProfile,
      meta: { title: 'プロフィール変更画面' }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
