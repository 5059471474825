<template>
  <b-modal id="reservation-modal-01" title="登録確認画面" hide-footer @hidden="$emit('cancelReservation')">
    <p class="mt-1 mb-3">
      以下の内容で{{ displayName }}予約を実施してよろしいですか？
    </p>
    <ol>
      <li>
        <h5>店舗：{{ reservationInfo.storeName }}</h5>
      </li>
      <li v-if="reservationType == 'facility'">
        <h5>設備：{{ reservationInfo.facilityName }}</h5>
      </li>
      <li v-if="reservationType == 'facility'">
        <h5>利用日：{{ reservationInfo.date }}</h5>
      </li>
      <li>
        <h5>利用開始時間：{{ reservationInfo.startTime }}</h5>
      </li>
      <li>
        <h5>利用終了時間：{{ reservationInfo.endTime }}</h5>
      </li>
      <li>
        <h5>利用料金：{{ reservationInfo.usageFee }} 円</h5>
      </li>
    </ol>
    <div class="d-flex justify-content-around mt-3">
      <b-button
        variant="outline-secondary"
        :disabled="reserveProcceing"
        @click="$bvModal.hide('reservation-modal-01')"
      >
      選び直す
      </b-button>
      <b-button
        variant="outline-primary"
        :disabled="reserveProcceing"
        @click="registerReserving('reservation-modal-01')"
      >
      続ける
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { reserveTmp, reserve } from '../api';

export default {
  name: 'ReservationModal',
  components: {},
  props: {
    reservationType: {
      type: String,
      default: ''
    },
    accessToken: {
      type: String,
      default: ''
    },
    reservationInfo: {
      type: Object,
      default: () => ({})
    },
    needsModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hyperlink: process.env.VUE_APP_LINE_ACCOUNT_URL,
      reserveProcceing: false
    }
  },
  computed: {
    displayName() {
      let displayName = '';
      if (this.reservationType == 'dropin') {
        displayName = 'ドロップイン';
      } else if (this.reservationType == 'facility') {
        displayName = '設備';
      }
      return displayName
    }
  },
  watch: {
    needsModal(needsModal) {
      if (needsModal) {
        this.$bvModal.show('reservation-modal-01');
      } else {
        this.$bvModal.hide('reservation-modal-01');
      }
    }
  },
  methods: {
    async registerReserving(modalId) {
      this.reserveProcceing = true;
      let requestBody = {
        facility_id: this.reservationInfo.targetFacility,
        start_datetime: this.reservationInfo.isoStartTime,
        end_datetime: this.reservationInfo.isoEndTime
      };
      try {
        // usageFeeが0円の場合はGMOペイメントを経由しない本予約登録APIを実施
        if (this.reservationInfo.usageFee === 0) {
          await reserve(this.accessToken, requestBody);
          this.$emit(
            'needsFlashMessage',
            'withHyperlink',
            'success',
            { 
              firstHalfMessage : this.displayName + 'の予約が完了しました。',
              hyperlinkMessage: 'こちら',
              hyperlink: this.hyperlink,
              laterHalfMessage: 'をタップして、LINEへの暗証番号通知の確認をお願いいたします。'
            }
          );
        } else {
          requestBody['usage_fee'] = this.reservationInfo.usageFee;
          await reserveTmp(this.accessToken, requestBody);
          this.$emit(
            'needsFlashMessage',
            'withHyperlink',
            'success',
            { 
              firstHalfMessage : this.displayName + 'の仮予約が完了しました。',
              hyperlinkMessage: 'こちら',
              hyperlink: this.hyperlink,
              laterHalfMessage: 'をタップして、LINEへの仮予約通知の確認をお願いいたします。'
            }
          );
        }
      } catch (err) {
        const errorReason = err.response?.data.error || err.message;
        let errorMessage = '';
        let hyperlinkMessageObj = {};
        switch (errorReason) {
        case 'no_reservation_space':
          if (this.reservationType == 'dropin') {
            errorMessage = '利用者が定員に達したため指定の時間帯が予約できなくなりました。お手数ですが再度時間帯を指定してください。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '指定の時間帯について他のユーザーにより設備が予約されてしまいました。お手数ですが再度時間帯を指定してください。';
          }
          break;
        case 'no_acceptable_time':
          if (this.reservationType == 'dropin') {
            errorMessage = 'ドロップイン受付時間外のため予約の受付ができません。後日のご利用をお待ちしております。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '本日は設備予約時間外のため予約の受付ができません。大変申し訳ありませんが後日のご利用をご検討ください。';
          }
          break;
        case 'store_closed':
          if (this.reservationType == 'dropin') {
            errorMessage = '本日は休業日のためドロップイン予約の受付ができません。後日のご利用をお待ちしております。';
          } else if (this.reservationType == 'facility') {
            errorMessage = '指定の日時は店舗休業日のため設備の予約ができません。大変申し訳ありませんが後日のご利用をご検討ください。';
          }
          break;
        case 'no_acceptable_plan':
          errorMessage = 'お客様はドロップイン予約が不要な会員種別です。施設の利用に当たっては事前に配布したPINコードをご利用ください。';
          break;
        case 'after_cancellation_plan':
          hyperlinkMessageObj['firstHalfMessage'] = '指定の日時はお客様の契約期間外となります。当該日時での予約を希望される場合は、契約更新の希望有無を書き添えて';
          hyperlinkMessageObj['hyperlinkMessage'] = 'こちら';
          hyperlinkMessageObj['hyperlink'] = 'https://docs.google.com/forms/d/e/1FAIpQLSdrjeW4J6N-s5dU5VCG8gB9tIRGgbZZJb-1mgla75w34b4Wkw/viewform';
          hyperlinkMessageObj['laterHalfMessage'] = 'のフォームまたはhowlive事務局 staff@howlive.jp までメールにてご連絡ください。';
          break;
        case 'invalid_plan':
          hyperlinkMessageObj['firstHalfMessage'] = '現在お客様の契約プランは失効しております。当該日時での予約を希望される場合は、契約更新の希望有無を書き添えて';
          hyperlinkMessageObj['hyperlinkMessage'] = 'こちら';
          hyperlinkMessageObj['hyperlink'] = 'https://docs.google.com/forms/d/e/1FAIpQLSdrjeW4J6N-s5dU5VCG8gB9tIRGgbZZJb-1mgla75w34b4Wkw/viewform';
          hyperlinkMessageObj['laterHalfMessage'] = 'のフォームまたはhowlive事務局 staff@howlive.jp までメールにてご連絡ください。';
          break;
        default:
          errorMessage = 'システムエラーにより予約に失敗しました。お手数ですが、しばらく時間を置いてから、再度操作をしていただけますようお願いいたします。';
        }
        if (['after_cancellation_plan', 'invalid_plan'].includes(errorReason)) {
          this.$emit('needsFlashMessage', 'withHyperlink', 'error', hyperlinkMessageObj);
        } else {
          this.$emit('needsFlashMessage', 'plain', 'error', { message: errorMessage });
        }
      } finally {
        this.reserveProcceing = false;
        this.$bvModal.hide(modalId);
      }
    }
  }
};
</script>

<style scoped></style>
