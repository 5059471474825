<template>
  <b-container>
    <b-alert
      :show="dismissCountDown"
      :variant="messageType"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      {{ message }}
    </b-alert>
  </b-container>
</template>

<script>
export default {
  name: 'PlainTextFlashMessage',
  components: {},
  props: {
    showAlert: {
      type: Boolean,
      default: false
    },
    messageType: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0
    };
  },
  watch: {
    showAlert(showAlert) {
      if (showAlert) {
        this.dismissCountDown = this.dismissSecs
      }
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown == 0) {
        this.$emit('hidePlainTextFlashMessage');
      }
    }
  }
};
</script>

<style scoped></style>
