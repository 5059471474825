<template>
  <div class="modifyng-user-profile-form">
    <b-container>
      <b-alert
        v-model="showUnexpectedEmailFormatAlert"
        variant="danger"
        dismissible
      >
        メールアドレス欄に不正な値が入力されています。メールアドレスの形式で入力してください。またスペースが混入していないかも確認してください。
      </b-alert>
      <b-alert
        v-model="showUnexpectedPhoneNumberFormatAlert"
        variant="danger"
        dismissible
      >
        電話番号欄に不正な値が入力されています。数字以外の値を入力しないでください。またスペースが混入していないかも確認してください。
      </b-alert>
      <b-alert v-model="showSameValueAlert" variant="danger" dismissible>
        メールアドレス・電話番号ともに現在値から変更がないため登録処理は行われませんでした。変更したい項目の値を変更してください。
      </b-alert>
      <b-alert
        v-model="showDuplicateRegistrationAlert"
        variant="danger"
        dismissible
      >
        すでに登録済みのメールアドレスのため変更ができません。
      </b-alert>
      <b-alert
        v-model="showModifyingMailApiErrorAlert"
        variant="danger"
        dismissible
      >
        システムエラーにより、変更が失敗しました。お手数ですが、しばらく時間を置いてから、再度操作をしていただけますようお願いいたします。
      </b-alert>
      <b-alert
        v-model="showSucceedInChangingOnlyPhoneNumberAlert"
        variant="success"
        dismissible
      >
        変更が完了しました。
      </b-alert>
      <b-alert
        v-model="showSucceedInChangingEmailAlert"
        variant="success"
        dismissible
      >
        変更が完了しました。変更後のメールアドレスに確認メールを送信しました。24時間以内にメールに記載されたURLをクリックして本登録を完了させてください。
      </b-alert>
      <b-form>
        <b-form-group
          id="input-group-1"
          label="メールアドレス:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="afterEmail"
            label="メールアドレス"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="電話番号:" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="afterPhoneNumber"
            label="電話番号"
          ></b-form-input>
        </b-form-group>
        <div class="row justify-content-center">
          <b-button class="mt-4 text-light" variant="primary" @click="register">
            登録する
          </b-button>
        </div>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { updateUser } from '../api';
import liff from '@line/liff';

export default {
  props: {
    email: {
      type: String,
      default: ''
    },
    phoneNumber: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showUnexpectedEmailFormatAlert: false,
      showUnexpectedPhoneNumberFormatAlert: false,
      showSameValueAlert: false,
      showDuplicateRegistrationAlert: false,
      showModifyingMailApiErrorAlert: false,
      showSucceedInChangingOnlyPhoneNumberAlert: false,
      showSucceedInChangingEmailAlert: false,
      accessToken: '',
      beforeEmail: '',
      beforePhoneNumber: '',
      afterEmail: '',
      afterPhoneNumber: '',
      emailIsNotChanged: false
    };
  },
  mounted() {
    this.beforeEmail = this.afterEmail = this.email;
    this.beforePhoneNumber = this.afterPhoneNumber = this.phoneNumber;
  },
  methods: {
    validate() {
      // フラッシュメッセージの初期化
      this.showUnexpectedEmailFormatAlert = false;
      this.showUnexpectedPhoneNumberFormatAlert = false;
      this.showSameValueAlert = false;
      this.showDuplicateRegistrationAlert = false;
      this.showModifyingMailApiErrorAlert = false;
      this.showSucceedInChangingOnlyPhoneNumberAlert = false;
      this.showSucceedInChangingEmailAlert = false;

      this.emailIsNotChanged = this.beforeEmail == this.afterEmail;
      const phoneNumberIsNotChanged = this.beforePhoneNumber == this.afterPhoneNumber;
      const emailRegex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.\-+]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
      const phoneNumberRegex = /^(0{1}\d{9,10})$/;

      try {
        if (!emailRegex.test(this.afterEmail)) {
          this.showUnexpectedEmailFormatAlert = true;
          throw new Error('email format');
        }

        if (!phoneNumberRegex.test(this.afterPhoneNumber)) {
          this.showUnexpectedPhoneNumberFormatAlert = true;
          throw new Error('phone number format');
        }

        if (this.emailIsNotChanged && phoneNumberIsNotChanged) {
          this.showSameValueAlert = true;
          throw new Error('not changed');
        }

        return true;

      } catch (error) {
        console.log('validation error: ' + error.message);
        return false;
      }
    },
    async register() {
      if (this.validate()) {
        try {
          await liff.init({
            liffId: process.env.VUE_APP_LIFF_ID,
            withLoginOnExternalBrowser: false
          });
          this.accessToken = await this.getLineAccessToken();
          await updateUser(
            this.accessToken,
            {
              'email': this.afterEmail,
              'telephone_number': this.afterPhoneNumber
            }
          );
          if (this.emailIsNotChanged) {
            this.showSucceedInChangingOnlyPhoneNumberAlert = true;
          } else {
            this.showSucceedInChangingEmailAlert = true;
          }
        } catch (error) {
          if (error.response.status == 400
              && error.response.data.error == 'duplicate_data') {
            this.showDuplicateRegistrationAlert = true;
          } else {
            this.showModifyingMailApiErrorAlert = true;
          }
        }
      }
    },
    async getLineAccessToken() {
      const accessToken = liff.getAccessToken();
      return accessToken;
    }
  }
};
</script>

<style scoped>
.modifyng-user-profile-form {
  text-align: left;
}
</style>
