<template>
  <span class="font-weight-bold pt-0">
    <div>
      <b-link v-b-modal.modal-scrollable href="#">利用規約</b-link>

      <b-modal
        id="modal-scrollable"
        size="xl"
        hide-footer
        scrollable
        title="利用規約"
      >
        <p>
          howliveはマッシグラ沖縄タイムスが運営するコワーキングスペース（以下「当スペース」）です。本利用ルールをよく読み、順守してください。順守して頂かない違反時にはご退場いただく場合がございます。尚、本サービスの利用状況等を勘案し、本ルールの変更の必要が生じた場合には変更することがあります。
        </p>

        <p>
          <strong>【感染症対策について】</strong><br />
          howliveでは、スタッフ一同消毒・換気など感染症対策を行っております。来館時に検温、アルコール手指消毒、体調チェック、マスク着用が必須となっております。
        </p>

        <p>
          コロナウイルス感染予防の為に、ひとつでも該当する項目があった場合は、ご利用を見合わせてください。
        </p>

        <p>
          ①37.5℃以上の発熱または平熱比１℃超過<br />
          ②咳、のどの痛みなど風邪の症状<br />
          ③だるさ（倦怠感）、息苦しさ（呼吸困難）<br />
          ④嗅覚や味覚の異常<br />
          ⑤新型コロナウイルス感染症陽性とされた方との濃厚接触<br />
          ⑥過去１４日以内に政府から入国制限、入国後の観察期間を必要とされている国、地域等への渡航又は当該在住者との濃厚接触がある場合<br />
        </p>

        <p>
          利用中は、常時マスクの着用・手指の消毒などのご協力をお願いいたします。<br />
          スタッフより、都度ご協力のお声掛けをすることがあるかと思いますが、ご協力頂きますよう何卒よろしくお願い申し上げます。
        </p>

        <p><strong>1.利用時間、利用エリア、休業日</strong><br /></p>
        <p>
          ・各店舗、営業時間が異なりますので下記確認をお願い致します。<br />
          （最終入館は店舗終了時間1時間前まで）
        </p>
        <ul>
          <li>タイムスビル店: 9:00~18:00 平日</li>
          <li>イオンモール沖縄ライカム店: 9:00~20:00 土日祝祭日含</li>
          <li>読谷村残波岬店:9:00~18:00 平日</li>
          <li>名護宮里店: 9:00~21:00 土日祝祭日含</li>
          <li>宮古島店: 9:00~18:00 平日</li>
        </ul>
        <p>
          ※緊急事態宣言の内容、今後の社会情勢、政府、県知事からの要請により、変更となる場合がございます。何卒ご理解・ご協力のほどよろしくお願いいたします。
        </p>
        <p>
          ・休業日: http://howlive.jp/で事前告知致します。<br />
          受付にて「howlive会員カード」を受け取り、滞在中は首から下げ、お帰りの際にご返却ください。<br />
          利用規約をよく読み、順守することに同意する署名をお願いします。
          ご利用料は事後精算です。料金は公式サイト又は受付でご確認ください。<br />
          <span><a href="http://howlive.jp/">http://howlive.jp/</a></span>
        </p>

        <p>
          <strong>2.会議室利用について</strong><br />
          料金、お申込み、詳細ルールについては公式サイトをご確認ください。
        </p>
        <p>
          <strong>3.設備の利用ルール</strong><br />
          貸出備品として、iphone
          充電器等ご用意していますが数に限りがございます。予めご了承ください。また貸し出した備品は必ずお帰りまでにご返却ください。カードを含め、貸与物の破損・紛失の場合には弁償していただきます。(カード弁償代：10,000円/税別)
        </p>
        <p>
          <strong>4.禁止事項</strong><br />
          howlive利用者は、以下の各号に該当する行為や当スペースまたはご利用者様に損害や迷惑を及ぼす行為を行ってはいけません。ルール違反と判断された段階で強制退出していただき、また今後のご利用をお断りさせていただきます。
        </p>
        <ol>
          <li>ゲスト打ち合わせでのご利用(人数分料金が発生します)</li>
          <li>大声での会話やお電話、騒音を出すなど、他のご利用者様や会員の迷惑となる行為</li>
          <li>設備の破損･破壊･盗難等を行う行為</li>
          <li>当社員や当会員への営業活動、ネットワークビジネスや宗教、自己啓発セミーなどへの勧誘行為</li>
          <li>匂いの強いお弁当やカップ麺などの汁物、アルコール類のお持ち込み</li>
          <li>当スペース内での喫煙 ※給湯室横の外部喫煙スペースをご利用ください。</li>
          <li>店舗内へのペットの持ち込み（ただし、盲導犬や介護犬の場合には、動物アレルギーをお持ちの方が先に入店されているか否か等を確認させていただいた上で、ご入店いただける場合があります。）</li>
          <li>泥酔状態でのご利用</li>
          <li>ジャージやスエットでのご入店</li>
          <li>反社会的、暴力的、猟奇的な情報の発信をする行為</li>
          <li>暴力団員が関係する一切の事業</li>
          <li>わいせつ、児童ポルノ若しくは児童虐待に当たる画像、文書等を送信又は掲載する行為</li>
          <li>システムへの不正アクセスを試みる行為、その他運営を妨げる行為</li>
          <li>セクシャルハラスメント・パワーハラスメント・ストーカー等の他人への迷惑行為</li>
          <li>法令若しくは公序良俗に違反し、又は第三者若しくは弊社に不利益を与える行為</li>
          <li>その他、当スペースが不適切と判断した行為</li>
        </ol>
        <p>
          <strong>5.皆さんが気持ちよくご利用いただくために</strong><br />
          howlive利用者は、以下の各号に該当する行為や当スペースまたはご利用者様に損害や迷惑を及ぼす行為を行ってはいけません。ルール違反と判断された段階で強制退出していただき、また今後のご利用をお断りさせていただきます。
        </p>
        <ol>
          <li>ゴミは必ずゴミ箱に捨ててください。</li>
          <li>お帰り時、移動させた机・イス・備品などは元の場所にお戻しください。</li>
          <li>撮影は可能ですが、周囲の方の顔は写りこまないようご配慮ください。</li>
          <li>自分の荷物はご自身で管理してください。</li>
          <li>仮眠やソファへの足上げはご遠慮ください。</li>
        </ol>
        <p>
          <strong>6.免責事項</strong><br />
        </p>
        <ol>
          <li>ご利用者同士のトラブルについては、一切関知せず、また、一切の責任を負わないものとします。</li>
          <li>当社に帰責性がある場合を除き、当施設内で会員に発生した人的・物的損害について一切責任を負わないものとします。</li>
          <li>忘れ物の保管期間は最大1 ヶ月までとします。１ヶ月過ぎた場合は処分させていただきます。（貴重品は所轄の警察署に届け出します)</li>
        </ol>
        <p>
          <strong>7.個人情報の取扱いについて</strong><br />
          当社は、お預かりした個人情報について次のとおり取り扱います。
        </p>
        <ol>
          <li>個人情報につきましては、以下に記載する利用目的を超えない範囲において使用致します。</li>
          <ol type="a">
            <li>利用者の確認・管理・メールマガジンの配信</li>
          </ol>
          <li>当社は取得した個人情報を適切に管理し、予めご本人の同意を得ることなく第三者に提供することはありません。</li>
          <li>当社は個人情報の取扱いを伴う業務の委託等のため弊社が保有する個人情報をビジネスパートナーに預ける必要がある場合は、信頼に足るパートナーを選定した上で、漏えい等の事故が発生しないようビジネスパートナーを契約により義務付け、適切な管理を実施します。</li>
          <li>利用者は、当社に対してご自身の個人情報の開示等（内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止）に関して申し出ることができます。その際、当社はご本人確認をさせていただいたうえで合理的な時間内に対応します。</li>
        </ol>
      </b-modal>
      に同意しました
    </div>
  </span>
</template>

<script>
export default {};
</script>
