export function plans() {
  return [
    {
      text: '法人',
      value: 'corporation'
    },
    {
      text: 'デスク',
      value: 'desk'
    },
    {
      text: 'クラウド',
      value: 'cloud'
    },
    {
      text: '個室',
      value: 'private-room'
    },
    {
      text: 'バーチャル',
      value: 'virtual'
    }
  ];
}
