export function jobs() {
  return [
    '会社員',
    '公務員',
    '自営業',
    '会社経営',
    '自由業',
    '専業主婦（夫）',
    '学生',
    'パート・アルバイト',
    '無職'
  ];
}
