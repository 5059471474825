<template>
  <div class="send-result">
    <div class="email">{{ email }}</div>
    <div class="message">
      <div>上記メールアドレスに確認メールを送信しました。</div>
      <div>
        メールアドレスに記載されたURLから24時間以内に登録を完了してください。
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.email {
  font-size: 20px;
  font-weight: bold;
}
.message {
  font-size: 15px;
  padding: 10px 10px;
}
</style>
